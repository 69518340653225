$(document).ready(function () {
  $(".slick-carousel").slick({
    nextArrow: ".next",
    prevArrow: ".previous",
  });

  $(".mobile-archive").slick({
    nextArrow: ".next",
    prevArrow: ".previous",
  });

  $("nav .burger").click(function () {
    $(".mobile-menu").slideDown();
  });

    $(".mobile-menu ul li .close").click(function () {
    $(".mobile-menu").slideUp();
  });

  var sourceSwap = function () {
    var $this = $(this);
    var newSource = $this.data("alt-src");
    $this.data("alt-src", $this.attr("src"));
    $this.attr("src", newSource);
  };

  $(function () {
    $(".mobile-menu ul li .close").hover(sourceSwap);
  });

  $(function () {
    $(".top-logo").hover(sourceSwap);
  });

  $(function () {
    $(".burger").hover(sourceSwap);
  });

  // $(".mobile-menu ul li").click(function () {
  //   $(".mobile-menu").slideUp();
  // });

  $(".mobile-menu ul li a").click(function () {
    $(".mobile-menu").fadeOut();
  });

  if (matchMedia) {
    const mq = window.matchMedia("(min-width: 701px)");
    mq.addListener(WidthChange);
    WidthChange(mq);
  }

  // media query change
  function WidthChange(mq) {
    if (mq.matches) {
      $.fn.slideFadeToggle = function (speed, easing, callback) {
        return this.animate(
          { opacity: "toggle", height: "toggle" },
          speed,
          easing,
          callback
        );
      };
      $;
      $(".main-header h1").css("opacity", "1");
      $(".main-header").delay(2000).slideUp(500);
      $(".mobile-header").hide();

      var mySwiper = new Swiper(".swiper-container", {
        direction: "vertical",
        speed: 700,
        pagination: ".swiper-pagination",
        paginationClickable: true,
        loop: true,
        mousewheelControl: true,
        hashnav: true,
        replaceState: true,
        effect: "slide",
        watchOverflow: true,
      });
      $(".title").click(function () {
        $(this).fadeOut(500);
        $(".info").css("display", "flex").hide().delay(700).fadeIn();
      });

      $(document).on("keydown", function (e) {
        if (e.keyCode === 27) {
          // ESC
          $(".info").fadeOut(500);
          $(".title").delay(700).fadeIn();
        }
      });

      $(".info").click(function () {
        $(this).fadeOut(500);
        $(".title").delay(700).fadeIn();
      });
    } else {
      $("#work").hide();
      $("header").hide();
      $("#mobile-work").show();
      $(".mobile-header h1").css("opacity", "1");

      $(".mobile-header").delay(2000).slideUp(1000);
      $(".slick-carousel").slick({
        nextArrow: ".next",
        prevArrow: ".previous",
      });
    }
  }

  $(".archive-slide").click(function (e) {
    e.preventDefault();
    var url = $(this).data("target");
    window.open(url, "_blank");
  });

  setTimeout(function () {
    $("#archive").css("opacity", "1");
  }, 400);

  setTimeout(function () {
    $("#mobile-archive").css("opacity", "1");
  }, 400);

  setTimeout(function () {
    $("#info").css("opacity", "1");
  }, 200);

  // $(function () {
  //   if ($("body").is(".home")) {
  //     setTimeout(function () {
  //       $("nav .content ul li .work").addClass("active");
  //     }, 2000);
  //   }
  // });

  if ($('body').hasClass('home')){
    setTimeout(function () {
        $("nav .content ul li .work").addClass("active");
      }, 2000);
  } else {
    null
  }
});
